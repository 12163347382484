@import '~@/assets/css/global/style/theme/index.css';

.el-button {
  & + .el-button {
    margin: 0;
    @include rtl-sass-prop(margin-left, margin-right, 16px);
  }
}

.el-input {
  &.el-input--small {
    font-size: 14px;
  }

  .el-input__inner {
    padding: 8px;
    // color: $info;
  }
}

.el-select {
  .el-input .el-select__caret {
    color: $text-secondary;
  }
}

.el-card {
  border-radius: 8px;
  border: none;

  .el-card__header {
    @include screen-mobile {
      padding: 12px 12px 0;
    }
    border-bottom: none;
    padding: 18px 20px 0;
    font-weight: 700;
    font-size: 16px;
  }

  .el-card__body {
    padding: 24px;
    @include screen-mobile {
      padding: 12px;
    }
  }
}

.el-popover--plain {
  padding: 16px;
}

.el-form {
  .el-form-item--small.el-form-item {
    margin-bottom: 24px;

    .el-form-item__label {
      line-height: 22px;
      color: $text-secondary;
    }
  }
  &.el-form--label-top .el-form-item__label {
    padding: 0 0 8px 0;
  }
}

.el-message-box {
  width: 480px;
  max-width: 90%;
  box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.2);
  border-radius: 16px;
  @include rtl-sass-value(text-align, left, right);

  .el-message-box__header {
    padding: 16px 24px;
    border-bottom: 1px solid $border-color-base;

    .el-message-box__title {
      font-size: 16px;
      font-weight: 500;
      position: relative;
      @include rtl-sass-prop(padding-left, padding-right, 12px);

      &::before {
        content: '';
        display: block;
        width: 4px;
        height: 16px;
        border-radius: 2px;
        background-color: $primary;
        position: absolute;
        top: 0;
        @include rtl-sass-prop(left, right, 0);
      }
    }

    .el-message-box__headerbtn {
      top: 16px;
      @include rtl-sass-prop(right, left, 24px);
      @include rtl-sass-prop(left, right, unset);

      .el-message-box__close {
        font-size: 16px;
        color: $black;
      }
    }
  }

  .el-message-box__content {
    padding: 40px 24px;
    @include screen-mobile {
      padding: 40px 16px;
    }
  }

  .el-message-box__btns {
    padding: 0 15px 14px;
    text-align: center;

    button:nth-child(2) {
      @include rtl-sass-prop(margin-left, margin-right, 24px);
    }
  }
}

.el-table {
  border-radius: 2px;
  overflow: hidden;
  th.el-table__cell {
    font-size: 14px;
    font-weight: 700;
    background-color: #ededf2;
  }

  .cell {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  &.el-table--small td {
    padding: 12px 0;
  }
}

.el-empty {
  .el-empty__description {
    color: $text-placeholder;
    margin-top: 24px;
    p {
      line-height: 1.5;
    }
  }
}

.el-dialog {
  .el-dialog__header {
    .el-dialog__headerbtn {
      @include rtl-sass-prop(right, left, 20px);
      @include rtl-sass-prop(left, right, unset);
    }

    .el-dialog__close {
      color: $black;

      &:hover {
        color: $primary;
      }
    }
  }
}
/* 修改滚动条样式开始 */
/*定义滚动条宽高  背景色*/
.scroll-box::-webkit-scrollbar,
.el-pagination_box::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.scroll-box::-webkit-scrollbar-track,
.el-pagination_box::-webkit-scrollbar-track,
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgb(245, 248, 252);
}
/* 修改滚动条样式结束 */

.el-input__suffix {
  @include rtl-sass-prop-sd(left, 5px);
  @include rtl-sass-prop-sd(right, unset);
}

/* form 表单开始 */
.el-form-item__label {
  @include rtl-sass-value(text-align, left, right);

  &:before {
    @include rtl-sass-prop(margin-left, margin-right, 0 !important);
    @include rtl-sass-prop(margin-right, margin-left, 4px);
  }
}

.el-form-item__error {
  @include rtl-sass-prop(left, right, 0);
}

.required_icon {
  @include rtl-sass-prop(margin-left, margin-right, 0);
  @include rtl-sass-prop(margin-right, margin-left, 4px);
}

.el-checkbox {
  white-space: pre-wrap;
  width: 100%;

  .el-checkbox__label {
    width: calc(100% - 36px);
    @include rtl-sass-prop(padding-left, padding-right, 4px);
    color: $text-secondary;
  }
}

/* upload 开始 */
.el-upload__tip {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 0;
  color: $info;
  @include rtl-sass-value(text-align, left, right);
}

.el-upload-list__item-name {
  @include rtl-sass-prop(margin-right, margin-left, 40px);
  @include rtl-sass-prop(margin-left, margin-right, unset);
  @include rtl-sass-prop(padding-left, padding-right, 4px);
  @include rtl-sass-prop(padding-right, padding-left, unset);
}

.el-upload-list__item-status-label {
  @include rtl-sass-prop(right, left, 5px);
  @include rtl-sass-prop(left, right, unset);
}

.el-upload-list__item-name [class^='el-icon'] {
  @include rtl-sass-prop(margin-right, margin-left, 7px);
  @include rtl-sass-prop(margin-left, margin-right, unset);
}

.el-upload-list__item .el-icon-close {
  @include rtl-sass-prop(left, right, unset);
  @include rtl-sass-prop(right, left, 5px);
}

/* upload 结束 */

/* Menu 开始 */

.el-tooltip__popper.is-dark {
  background: #04071c !important;
  height: 56px;
  @include rtl-sass-prop(margin-left, margin-right, 0);
  @include rtl-sass-prop(margin-right, margin-right, 5px);
  @include rtl-sass-prop(padding-left, padding-right, 20px);
  min-width: 200px;
  @include rtl-sass-value(border-radius, 0 8px 8px 0, 8px 0 0 8px);
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    font-size: 15px;
    color: $white;
  }

  &:hover {
    background: #0e1236 !important;

    span {
      color: #5ad6cf;
    }
  }
}

.el-menu--popup {
  box-shadow: none;
}

.el-menu--popup-right-start {
  margin: 0;
  background-color: transparent !important;
}

.el-menu--popup li {
  height: 56px;
  background: #04071c !important;
  color: $white !important;
  display: flex;
  align-items: center;

  &:first-child {
    @include rtl-sass-value(border-radius, 0 8px 0 0, 8px 0 0 0);
  }

  &:last-child {
    @include rtl-sass-value(border-radius, 0 0 8px 0, 0 0 0 8px);
  }

  &.is-active,
  &:hover {
    background: #0e1236 !important;
    color: #5ad6cf !important;
  }
}

.el-tooltip__popper .popper__arrow,
.el-tooltip__popper .popper__arrow::after {
  display: none;
}

.el-popper[x-placement^='bottom'] .popper__arrow::after {
  @include rtl-sass-prop(margin-left, margin-right, -6px);
}

.el-menu--popup {
  padding: 0;
}

/* Menu 结束 */

.el-scrollbar__wrap {
  scrollbar-width: none;
  overflow: scroll;
  @include rtl-sass-prop-dual(margin-right, 0, margin-right, 0 !important);
  @include rtl-sass-prop-dual(margin-left, 0, margin-left, -6px !important);
}

/* 修改手機點擊兩次 */
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1;
  }
}

[class*='el-col-'] {
  @include rtl-sass-value(float, left, right);
}

.el-message__icon {
  @include rtl-sass-prop-sd(margin-left, 10px);
  @include rtl-sass-prop-sd(margin-right, 0);
}

.el-input__prefix {
  @include rtl-sass-prop(left, right, 5px);
  @include rtl-sass-prop(right, left, unset);
}

.el-date-editor {
  &.el-input .el-input__inner {
    padding-left: 30px;
  }
}

[v-cloak] {
  display: none;
}

// 级联
.el-popper.el-cascader__dropdown {
  max-width: 100vw;
  overflow-x: scroll;

  .el-cascader-panel {
    overflow-x: scroll;

    .el-cascader-menu {
      min-width: 300px;
    }
  }
}
